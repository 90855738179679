import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./LoginPage.module.css";
import sensationLogo from "../../assets/sensationLogo.png";
import { updateUser } from "../../store/userSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { generate } from "../../store/otpSlice";
import * as Yup from "yup";
import axios from "axios";
import useToast from "../../utils/toast/useToast";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { IoPersonSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlinePhonelinkLock } from "react-icons/md";
import headerImage from "../../assets/loginHeaderimg.png";
import {
  checkUser,
  createNotificationUser,
  generateToken,
  getUserData,
  resendOtp,
  sendOtp,
  verifyOtp,
} from "../../services/user";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import CommanLoader from "../../components/elements/common/Loader/CommanLoader";

const LoginPage = ({ setShowLogin, loginState, setGuestId, guestUserId }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [count, setCount] = useState(60);
  const [loadingState, setLoadingState] = useState({
    otpSending: false,
    otpVerfying: false,
    otpResending: false,
    otpCanResend: false,
  });

  useEffect(() => {
    if (count === 0) {
      setLoadingState((prev) => ({ ...prev, otpCanResend: true }));
      return;
    }
    const timerId = setTimeout(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [count]);

  const [state, setState] = useState({
    tab: 1,
    otpIsVerified: false,
    userExists: false,
  });
  axios.defaults.withCredentials = true;
  const dispatch = useDispatch();
  const notificationtoken = localStorage.getItem("notificationtoken");

  async function fetchNotification(userData) {
    const res = await createNotificationUser({
      userId: userData?._id,
      userName: userData?.userName,
      notification_token: notificationtoken,
    });
  }

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .length(10)
      .matches(/^[6789]\d{9}$/, "Invalid phone number")
      .required("Phone number is required"),
  });

  const ErrorMessagesSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(4)
      .matches(/^[0-9]{4}$/, "Invalid OTP number")
      .required("OTP number is required"),
    phoneNumber: Yup.string()
      .length(10)
      .matches(/^[6789]\d{9}$/, "Invalid phone number")
      .required("Phone number is required"),
    userName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Username must contain only letters.")
      .min(2, "Username is too short, it must be at least 2 characters.")
      .max(50, "Username is too long, it must not exceed 50 characters.")
      .required("Username is required."),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      )
      .nullable()
      .optional(),
  });

  const OtpErrorMessagesSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(4)
      .matches(/^[0-9]{4}$/, "Invalid OTP number")
      .required("OTP number is required"),
    phoneNumber: Yup.string()
      .length(10)
      .matches(/^[6789]\d{9}$/, "Invalid phone number")
      .required("Phone number is required"),
  });

  const handleSubmit = async (values) => {
    if (state.tab == 1) {
      setLoadingState((prev) => ({ ...prev, otpSending: true }));
      const formData = {
        phoneNumber: values.phoneNumber,
      };
      try {
        const response = await sendOtp(formData);
        if (response?.status === 200) {
          toast.showSuccessToast("OTP sent successfully");
          setCount(60);
          setLoadingState((prev) => ({
            ...prev,
            otpSending: false,
            otpCanResend: false,
          }));
          setState({
            ...state,
            tab: 2,
            phoneNumber: values.phoneNumber,
            userExists: response?.data?.userExists,
          });
        } else {
          toast.showErrorToast("Error Sending OTP.");
          setLoadingState((prev) => ({
            ...prev,
            otpSending: false,
            otpCanResend: false,
          }));
        }
      } catch (error) {
        toast.showErrorToast("Error Sending OTP.");
        setLoadingState((prev) => ({
          ...prev,
          otpSending: false,
          otpCanResend: false,
        }));
      }
    } else {
      setLoadingState((prev) => ({ ...prev, otpVerfying: true }));
      const formData =
        state.userExists == true
          ? {
              phoneNumber: state?.phoneNumber,
              otp: values.Otp,
              guestId:
                localStorage.getItem("guest_id") ||
                localStorage.getItem("guestUserId"),
            }
          : {
              userName: values.userName,
              phoneNumber: state?.phoneNumber,
              email: values.email || "",
              isVerified: true,
              guestId: localStorage.getItem("guestUserId"),
              phoneNumber: state?.phoneNumber,
              otp: values.Otp,
            };
      try {
        const response = await verifyOtp(formData);
        if (response?.status === 200) {
          setLoadingState((prev) => ({ ...prev, otpVerfying: false }));
          if (state?.userExists == true) {
            const response = await generateToken(formData, toast);
            if (response?.status === 201) {
              const data = await getUserData(toast);
              fetchNotification(data);
              localStorage.setItem("userDetails", JSON.stringify(data));
              localStorage.removeItem("guest_id");
              if (loginState) {
                if (loginState?.from === "/order") {
                  navigate("/order?login=true", {
                    replace: true,
                  });
                } else {
                  navigate(`${window.location.pathname}?logged=true`, {
                    replace: true,
                  });
                  setShowLogin(null);
                }
              } else {
                navigate(`${window.location.pathname}?logged=true`, {
                  replace: true,
                });
              }
              toast.showSuccessToast("Login successful");
              setShowLogin(null);
            }
          } else if (state.userExists == false) {
            try {
              const response = await checkUser(formData, toast);
              if (response?.status === 201) {
                localStorage.removeItem("guestUserId");
                const response = await generateToken(formData, toast);
                if (response?.status === 201) {
                  const data = await getUserData(toast);
                  fetchNotification(data);
                  localStorage.setItem("userDetails", JSON.stringify(data));
                  if (loginState) {
                    if (loginState?.from === "/order") {
                      navigate("/order?login=true", {
                        replace: true,
                      });
                    } else {
                      navigate(`${window.location.pathname}?logged=true`, {
                        replace: true,
                      });
                      setShowLogin(null);
                    }
                  } else {
                    navigate(`${window.location.pathname}?logged=true`, {
                      replace: true,
                    });
                  }
                  toast.showSuccessToast("Login successful");
                  setShowLogin(null);
                }
              }
            } catch (error) {
              console.log(error);
              setLoadingState((prev) => ({ ...prev, otpVerfying: false }));
            }
            setLoadingState((prev) => ({ ...prev, otpVerfying: false }));
          }
        } else {
          toast.showErrorToast("Invalid OTP.");
          setLoadingState((prev) => ({ ...prev, otpVerfying: false }));
        }
      } catch (error) {
        toast.showErrorToast("Error Sending OTP.");
        setLoadingState((prev) => ({ ...prev, otpVerfying: false }));
      }
    }
  };

  const handleGuest = async (e) => {
    e.preventDefault();
    const gid = localStorage.getItem("guest_id");
    if (gid) {
      localStorage.setItem("guestUserId", gid);
      if (loginState?.from === "/order") {
        navigate("/order?login=true", {
          replace: true,
        });
      } else {
        navigate(`${window.location.pathname}?logged=true`, {
          replace: true,
        });
      }
      return;
    }
    const res = await checkUser({ guest: true });
    if (res.status === 201) {
      localStorage.setItem("guestUserId", res.data._id);
      localStorage.setItem("guest_id", res.data._id);
      fetchNotification({
        _id: res.data._id,
        userName: `Guest_${res.data._id}`,
      });
      if (guestUserId) setGuestId(res.data._id);
      if (loginState) {
        if (loginState?.from === "/order") {
          navigate("/order?login=true", {
            replace: true,
          });
        } else {
          navigate(`${window.location.pathname}?logged=true`, {
            replace: true,
          });
        }
      } else {
        navigate(`${window.location.pathname}?logged=true`, {
          replace: true,
        });
      }
    }
  };

  const handleResendOtp = async (e) => {
    setLoadingState((prev) => ({ ...prev, otpResending: true }));

    try {
      const response = await resendOtp({ phoneNumber: state?.phoneNumber });
      if (response?.status === 200) {
        toast.showSuccessToast("OTP sent successfully");
        setLoadingState((prev) => ({
          ...prev,
          otpResending: false,
          otpCanResend: false,
        }));

        setCount(60);
      } else {
        toast.showErrorToast("Error Sending OTP.");
        setLoadingState((prev) => ({ ...prev, otpResending: false }));
      }
    } catch (error) {
      console.log(error);
      toast.showErrorToast("Error Sending OTP.");
      setLoadingState((prev) => ({ ...prev, otpResending: false }));
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowLogin(null);
    }
  };
  const handleKeyDown = (event, nextFieldName, submitForm) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (nextFieldName) {
        const nextField = document.querySelector(
          `input[name="${nextFieldName}"]`
        );
        if (nextField) {
          nextField.focus();
        }
      } else {
        // If there's no next field, submit the form
        submitForm();
      }
      // Close the keyboard
      event.target.blur();
    }
  };

  return (
    <>
      <div className={`${styles.overlay}`} onClick={handleOverlayClick}>
        <motion.div
          initial={{ translateY: "100%" }}
          animate={{ translateY: "0%" }}
          transition={{ duration: 0.4 }}
          className={styles.modalDialog}
          style={{
            position: "absolute",
            width: "100%",
            height: "fit-content",
            paddingBottom: "40px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="position-relative">
            <RxCross2
              size={40}
              onClick={() => setShowLogin(null)}
              className={styles.exit}
            />
            <div className={styles.main}>
              <div className="">
                <img src={headerImage} className={styles.logoImg} alt="img" />
              </div>
              <div className={styles.formContainer}>
                <div className={styles.mainContainer}>
                  <Formik
                    initialValues={
                      state.tab === 1
                        ? { phoneNumber: "" }
                        : state.userExists
                        ? { phoneNumber: state?.phoneNumber ?? "", Otp: "" }
                        : {
                            phoneNumber: state?.phoneNumber ?? "",
                            Otp: "",
                            userName: "",
                            email: "",
                          }
                    }
                    enableReinitialize
                    validationSchema={
                      state.tab == 1
                        ? DisplayingErrorMessagesSchema
                        : state.userExists
                        ? OtpErrorMessagesSchema
                        : ErrorMessagesSchema
                    }
                    onSubmit={(values, { setSubmitting }) => {
                      dispatch(updateUser(values));
                      handleSubmit(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ errors, touched, isSubmitting, handleSubmit }) => (
                      <Form
                        className={styles.LoginForm}
                        onSubmit={handleSubmit}
                      >
                        {state.tab == 2 ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              cursor: "pointer",
                              height: "35px",
                              width: "35px",
                              borderRadius: "50%",
                              backgroundColor: "var(--third-color)",
                            }}
                            onClick={() => setState({ ...state, tab: 1 })}
                          >
                            <MdOutlineArrowBackIosNew
                              size={24}
                              color="white"
                              className=""
                            />
                          </div>
                        ) : null}

                        {state.tab == 1 ? (
                          <div className={`${styles.formGroup} mt-5 `}>
                            <div className={styles.formIcon}>
                              <FaPhone style={{ marginBottom: "5px" }} />
                            </div>
                            <div style={styles.formInput}>
                              <Field
                                name="phoneNumber"
                                maxLength={10}
                                className={styles.input}
                                placeholder="Phone Number"
                                onKeyDown={(e) =>
                                  handleKeyDown(e, null, handleSubmit)
                                }
                              />
                            </div>

                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className={styles.error}
                            />
                          </div>
                        ) : (
                          <>
                            <div className={`${styles.formGroup} mt-5 `}>
                              <div className={styles.formIcon}>
                                <FaPhone style={{ marginBottom: "5px" }} />
                              </div>
                              <div style={styles.formInput}>
                                <Field
                                  name="phoneNumber"
                                  className={styles.input}
                                  maxLength={10}
                                  disabled={state?.phoneNumber ? true : false}
                                  placeholder="Phone Number"
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, "Otp", handleSubmit)
                                  }
                                />
                              </div>

                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className={styles.error}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <div className={styles.formIcon}>
                                <MdOutlinePhonelinkLock
                                  style={{ marginBottom: "5px" }}
                                />
                              </div>
                              <div style={styles.formInput}>
                                <Field
                                  name="Otp"
                                  maxLength={4}
                                  className={styles.input}
                                  placeholder="OTP"
                                  onKeyDown={(e) =>
                                    handleKeyDown(
                                      e,
                                      state?.userExists ? null : "userName",
                                      handleSubmit
                                    )
                                  }
                                />
                              </div>
                              <ErrorMessage
                                name="Otp"
                                component="div"
                                className={styles.error}
                              />
                            </div>
                            {state?.userExists === false ? null : (
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "white",
                                    color: loadingState.otpCanResend
                                      ? "blue"
                                      : "grey",
                                    border: "none",
                                  }}
                                  disabled={!loadingState.otpCanResend}
                                  onClick={handleResendOtp}
                                  className={`me-1 float-end`}
                                >
                                  {loadingState.otpCanResend
                                    ? "Resend OTP"
                                    : `Resend OTP in ${count}s`}
                                </button>
                              </div>
                            )}
                            {state?.userExists == false ? (
                              <>
                                <div className={styles.formGroup}>
                                  <div className={styles.formIcon}>
                                    <IoPersonSharp
                                      style={{ marginBottom: "5px" }}
                                    />
                                  </div>
                                  <div style={styles.formInput}>
                                    <Field
                                      name="userName"
                                      className={styles.input}
                                      placeholder="Full Name"
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, "email", handleSubmit)
                                      }
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="userName"
                                    component="div"
                                    className={styles.error}
                                  />
                                </div>
                                <div className={styles.formGroup}>
                                  <div className={styles.formIcon}>
                                    <MdEmail style={{ marginBottom: "5px" }} />
                                  </div>
                                  <div style={styles.formInput}>
                                    <Field
                                      name="email"
                                      className={styles.input}
                                      placeholder="Email (optional)"
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, null, handleSubmit)
                                      }
                                    />
                                  </div>

                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className={styles.error}
                                  />
                                </div>
                                <div className="form-group">
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "white",
                                      color: loadingState.otpCanResend
                                        ? "blue"
                                        : "grey",
                                      border: "none",
                                    }}
                                    disabled={!loadingState.otpCanResend}
                                    onClick={handleResendOtp}
                                    className={`me-1 float-end`}
                                  >
                                    {loadingState.otpCanResend
                                      ? "Resend OTP"
                                      : `Resend OTP in ${count}s`}
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                        {state.tab == 1 ? (
                          <div
                            className={
                              "d-flex w-100 mt-4 justify-content-center"
                            }
                          >
                            <motion.button
                              type="button"
                              className={`${styles.button} me-1`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleGuest(e);
                                setShowLogin(null);
                              }}
                              style={{
                                backgroundColor: "var(--primary-color)",
                              }}
                              whileTap={{ scale: 0.9 }}
                            >
                              Continue As Guest
                            </motion.button>
                            <motion.button
                              type="submit"
                              className={styles.button}
                              whileTap={{ scale: 0.9 }}
                              disabled={loadingState.otpSending || isSubmitting}
                            >
                              {loadingState.otpSending || isSubmitting ? (
                                <CommanLoader
                                  color={"white"}
                                  size={20}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              ) : (
                                "Login"
                              )}
                            </motion.button>
                          </div>
                        ) : (
                          <div
                            className={
                              "d-flex w-100 mt-4 justify-content-center"
                            }
                          >
                            <motion.button
                              type="button"
                              className={`${styles.button} me-1`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleGuest(e);
                                setShowLogin(null);
                              }}
                              style={{
                                backgroundColor: "var(--primary-color)",
                              }}
                              whileTap={{ scale: 0.9 }}
                            >
                              Continue As Guest
                            </motion.button>

                            <motion.button
                              type="submit"
                              className={styles.button}
                              whileTap={{ scale: 0.9 }}
                              disabled={
                                loadingState.otpVerfying || isSubmitting
                              }
                            >
                              {loadingState.otpVerfying || isSubmitting ? (
                                <CommanLoader
                                  color={"white"}
                                  size={20}
                                  style={{
                                    height: "30px",
                                    width: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              ) : (
                                "Continue"
                              )}
                            </motion.button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="d-flex w-100 justify-content-center bg-white mt-4">
                  <div className={styles.footer}>
                    <p className={styles.PoweredBy}>Powered by</p>
                    <img
                      src={sensationLogo}
                      className={styles.Sensation}
                      alt="Sensation Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default LoginPage;
