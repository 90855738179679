import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../styles/WelcomeUserPage.module.css";
import PrevOrderCard from "../components/PrevOrderDetailsPage/PrevOrderCard";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../services/user";
import useToast from "../utils/toast/useToast";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { clearCart } from "../store/cartSlice";
import { persistor } from "../store/store";
import { orderStatus, orderType } from "../constants";
import { getStatusColor } from "../constants";
import { fetchOrders, fetchDeliveredOrders } from "../services/ordersService";
import { HiOutlineUser } from "react-icons/hi2";
import EditUser from "../components/elements/common/EditUser";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SocketContext from "../socket/socket";
import dinein from "../assets/dinein.png";
import pickup from "../assets/food-pick-up.png";
import delivery from "../assets/food-delivery.png";
import CommanLoader from "../components/elements/common/Loader/CommanLoader";
import notificationSound from "../assets/notificationSound.mp3";
import CustomSlider from "../components/elements/common/Slider/CustomSlider";
import { Button } from "../components/elements/Button";
import LoginPage from "./LoginPage/LoginPage";
import OtpVerify from "./OtpVerify";

const HomePage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const orderNumber = localStorage.getItem("orderNumber");
  const [allOrders, setAllOrders] = useState([]);
  const [userData, setUserData] = useState(null);
  const [processedOrders, setProcessedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const allOrderItems = allOrders?.map((order) => order.items);
  const resId = localStorage.getItem("restaurantId");
  const [showLogin, setShowLogin] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId =
    userDetails?._id ||
    localStorage.getItem("guestUserId") ||
    localStorage.getItem("guest_id");

  console.log(JSON.parse(localStorage.getItem("userDetails"))?._id);

  useEffect(() => {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
  }, []);

  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);

  useEffect(() => {
    async function fetchData() {
      const data = await getUserData(toast);
      if (data) {
        setUserData(data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchOrdersData = async () => {
      const ordersData = await fetchOrders(resId, userId);
      setAllOrders(ordersData?.orders);
      localStorage.setItem(
        "latestOrder",
        JSON.stringify(ordersData?.orders[0])
      );
      setLoading(false); // Data fetching complete, set loading to false
    };
    fetchOrdersData();
  }, []);

  useEffect(() => {
    if (orderNumber) {
      dispatch(clearCart());
      localStorage.removeItem("orderNumber");
    }
  }, []);

  const fetchDeliveredOrdersData = async () => {
    const deliveredOrdersData = await fetchDeliveredOrders(resId, userId);
    setProcessedOrders(
      deliveredOrdersData?.orders?.filter(
        (order) =>
          order.orderStatus === "delivered" || order.orderStatus === "cancelled"
      )
    );
  };
  const playAudio = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const handleAudioEnd = () => {
    audioRef.current.currentTime = 0;
  };

  useEffect(() => {
    fetchDeliveredOrdersData();
  }, [allOrders]);

  const getOrderNames = (items) => {
    const names = items.flatMap((orderItems) => orderItems.name);
    return names.join(", ");
  };

  const handleOrderStatusUpdate = (data) => {
    if (data.userId === userId) {
      const updatedOrderNo = data.orderNo;
      const updatedOrder = data.updatedOrder;

      setAllOrders((prevOrders) => {
        // Get the previous order status before updating the state
        const previousOrderStatus = prevOrders.find(
          (order) => order?.orderNo === updatedOrderNo
        )?.orderStatus;

        let newOrders;

        if (
          updatedOrder.orderStatus === "delivered" ||
          updatedOrder.orderStatus === "cancelled"
        ) {
          newOrders = prevOrders.filter(
            (order) => order.orderNo !== updatedOrderNo
          );
          fetchDeliveredOrdersData();
        } else {
          // Update the processedOrders state with the new order status
          newOrders = prevOrders.map((order) =>
            order?.orderNo === updatedOrderNo
              ? { ...order, ...updatedOrder }
              : order
          );
        }

        // Compare the previous order status with the new one
        if (
          ["InProcess", "ready", "cancelled"].includes(
            updatedOrder?.orderStatus
          ) &&
          updatedOrder?.orderStatus !== previousOrderStatus
        ) {
          playAudio();
        }

        // Return the updated orders
        return newOrders;
      });

      localStorage.setItem(
        "latestOrder",
        JSON.stringify(
          allOrders?.orders && allOrders?.orders?.length && allOrders?.orders[0]
        )
      );
    }
  };

  const handleDeleteOrder = (data) => {
    if (data && data?.orderId && data?.userId === userId) {
      setAllOrders((prevOrders) =>
        prevOrders.filter((order) => order?._id !== data?.orderId)
      );
    }
  };

  useEffect(() => {
    socket?.on("deleteOrderUpdate", handleDeleteOrder);
    socket?.on("orderUpdateEvent", handleOrderStatusUpdate);

    // Cleanup function to remove the event listener on component unmount
    return () => {
      socket?.on("deleteOrderUpdate", handleDeleteOrder);
      socket?.off("orderUpdateEvent", handleOrderStatusUpdate);
    };
  }, [socket]);
  useEffect(() => {
    const latestOrder = localStorage.getItem("latestOrder");
    if (latestOrder) {
      const ifOrderExist = allOrders.filter(
        (order) => order?._id === JSON.parse(latestOrder)?._id
      );
      if (ifOrderExist.length === 0) {
        localStorage.removeItem("latestOrder");
      }
    }
  }, []);
  const settings = {
    className: "center",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    lazyLoad: "ondemand",
    style: { height: "auto", marginTop: "10px" },
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0.5, y: -50 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          ease: [0.17, 0.67, 0.83, 0.67],
          duration: 0.5,
          delay: 0.2,
        }}
        className={styles.container}
        exit={{ opacity: 0, y: 50, transition: { duration: 0.2 } }}
      >
        <div className={styles.header}>
          <div className={styles.welcome}>
            <span className={styles.usernametext}>
              {userData && userData.userName}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <Button
              value="Menu"
              onClick={() => {
                if (userId) {
                  navigate("/home?logged=true");
                } else {
                  navigate("/home");
                }
              }}
              style={{
                padding: "0 8px",
                width: "fit-content",
                marginRight: "10px",
              }}
              // backgroundColor,
            ></Button>
            <HiOutlineUser
              size={30}
              title="Profile"
              onClick={() => handleShow()}
              style={
                !showSidebar
                  ? { color: "black" }
                  : { color: "#FA4A0C", fontWeight: "bold" }
              }
            />
          </div>
        </div>
        <audio
          ref={audioRef}
          src={notificationSound}
          onEnded={handleAudioEnd}
        />
        <div className={styles.main}>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: [0.17, 0.67, 0.83, 0.67],
              duration: 0.3,
              delay: 0.2,
            }}
          >
            {
              <h4 className={`${styles.ordersHeading} mt-1 pt-3 mx-4 mb-0 `}>
                <span>CURRENT ORDER STATUS</span>
              </h4>
            }
            {showSidebar && (
              <EditUser
                handleClose={handleClose}
                showSidebar={showSidebar}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
              />
            )}
            {loading ? (
              <CommanLoader color={"#7a1b1b"} size={20} />
            ) : allOrders.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center fs-5 p-4 mx-4">
                No Current Orders to show
              </div>
            ) : (
              <CustomSlider
                settings={settings}
                ordersLength={allOrders?.length}
              >
                {allOrders?.map((item, index) => (
                  <div
                    className={`${styles.ordercard}`}
                    key={index}
                    onClick={() => {
                      localStorage.removeItem("reload-detail");
                      navigate(`/prevorder/${item._id}`, {
                        replace: true,
                      });
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.mainitemname}>
                        <span>{getOrderNames(item.items)} </span>
                      </div>
                      <div
                        className="rounded px-2 py-1"
                        style={{
                          width: "fit-content",
                          // border: "1px solid #7a1b1b",
                          background: "#98a12259",
                        }}
                      >
                        <img
                          src={
                            item &&
                            (item.orderType === orderType.PickUp
                              ? pickup
                              : item.orderType === orderType.Delivery
                              ? delivery
                              : dinein)
                          }
                          alt=""
                          height={22}
                          width={25}
                        />
                      </div>
                    </div>
                    <hr
                      style={{
                        borderTop: `1px solid ${getStatusColor(
                          orderStatus[item.orderStatus]
                        )}`,
                        color: getStatusColor(orderStatus[item.orderStatus]),
                      }}
                      className={styles.hrStyle}
                    />
                    <h6 className={styles.tableInfo}>
                      <span className="fw-bold">Order No:</span> {item.orderNo}
                    </h6>
                    <div className={styles.tabledetails}>
                      <h6 className={styles.tableInfo}>
                        {item?.tableName || item?.tableNumber}
                      </h6>
                      <h6
                        className={`${styles.processStatus}`}
                        style={{
                          color: "white",
                          // color: getStatusColor(orderStatus[item.orderStatus]),
                          backgroundColor: `${getStatusColor(
                            orderStatus[item.orderStatus]
                          )}`,
                        }}
                      >
                        {orderStatus[item.orderStatus] === "Received" ||
                        orderStatus[item.orderStatus] === "received"
                          ? "Pending"
                          : orderStatus[item.orderStatus] !== "InProcess"
                          ? orderStatus[item.orderStatus]
                          : "Preparing"}
                      </h6>
                    </div>
                    <div className={styles.totaldetails}>
                      <div className={styles.totalLabel}>Total</div>

                      <div className={styles.totalValue}>₹{item.total}</div>
                    </div>
                  </div>
                ))}
              </CustomSlider>
            )}
          </motion.div>{" "}
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: [0.17, 0.67, 0.83, 0.67],
              duration: 0.3,
              delay: 0.6,
            }}
          >
            <div className="mx-4 mt-3 overflow-hidden">
              {
                <h5 className={` my-2 ${styles.ordersHeading}`}>
                  <span>ORDER HISTORY</span>
                </h5>
              }
              {processedOrders ? (
                <PrevOrderCard orders={processedOrders} />
              ) : (
                <ShimmerSimpleGallery card imageHeight={100} row={5} col={1} />
              )}
            </div>
          </motion.div>
        </div>
      </motion.div>
      {showLogin === "login" ? <LoginPage setShowLogin={setShowLogin} /> : null}
      {showLogin === "otpverify" ? (
        <OtpVerify setShowLogin={setShowLogin} showLogin={showLogin} />
      ) : null}
    </>
  );
};

export default HomePage;
