import { MdOutlineArrowBackIos } from "react-icons/md";
import styles from "../styles/MyOrders.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../components/elements/Button";
import BillDetail from "../components/OrderPage/BillDetail";
import { useState, useEffect } from "react";
import CartItems from "../components/OrderPage/CartItems";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useCartTotals from "../hooks/useCartTotal";
import useToast from "../utils/toast/useToast";
import { sendNotification, userIsLogged } from "../services/user";
import OrderSuccesfulCard from "../components/elements/common/OrderSuccesfulCard";
import API from "../services/API";
import { orderStatus } from "../constants";
import { clearCart } from "../store/cartSlice";
import CommonModal from "../components/elements/common/CommonModel";
import LoginPage from "./LoginPage/LoginPage";
import OtpVerify from "./OtpVerify";

const MyOrders = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userLogged, setUserLogged] = useState(false);
  const orderData = JSON.parse(
    localStorage.getItem("orderDetails")
  )?.orderType?.split(",");
  const [orderType, setOrderType] = useState(
    orderData && orderData.length ? orderData[0] : []
  );
  const tableNumber = localStorage.getItem("tableNumber");
  const params = useParams();
  const confirm = params?.confirm;
  const tableId = localStorage.getItem("tableId");
  const itemsInCart = useSelector((state) => state.cart.items);
  const [orderSucessfull, setOrderSucessfull] = useState(false);
  const [cartitems, setCartItems] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const id = localStorage.getItem("restaurantId");
  const [showLogin, setShowLogin] = useState(null);
  const [loginState, setLoginState] = useState({ from: "" });
  const toast = useToast();
  const itemCount = localStorage.getItem("itemCount");
  const [clickOnConfirm, setClickOnConfirm] = useState(false);

  const handleOnClose = () => {
    // setShowRatingCard(true);
    navigate("/welcome", { replace: true });
  };
  const { total } = useCartTotals(cartitems);
  const date = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const time = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const handleConfirmOrder = async () => {
    setClickOnConfirm(true);
    let guest_id = localStorage.getItem("guestUserId");
    const oldguestid = localStorage.getItem("guest_id");

    if (guest_id && !oldguestid) {
      localStorage.setItem("guest_id", guest_id);
      localStorage.removeItem("guestUserId");
    } else {
      if (localStorage.getItem("guestUserId"))
        localStorage.removeItem("guest_id");
    }

    if (!userLogged && !guest_id) {
      setShowLogin("login");
      setLoginState({ from: "/order" });
      setClickOnConfirm(false);
      return;
    }
    guest_id = localStorage.getItem("guestUserId");
    if (userLogged || guest_id) {
      const restaurantId = localStorage.getItem("restaurantId");
      const userData = JSON.parse(localStorage.getItem("userDetails"));
      const taxes = JSON.parse(localStorage.getItem("taxes"));

      try {
        const response = await API.post("orders", {
          orderNo: orderNumber,
          orderStatus: orderStatus.Received,
          total: total,
          orderDate: date,
          orderTime: time,
          items: cartitems.map((item) => {
            return {
              ...item,
              instructions: item?.instructions
                ? `${item?.instructions}${
                    item?.selectedBadges?.length
                      ? ", " + item?.selectedBadges.join(",")
                      : ""
                  }`
                : item?.selectedBadges?.join(",") || "",
            };
          }),
          tableNumber: tableId,
          tableName: tableNumber,
          restaurant_id: id,
          user_id: userData?._id ? userData?._id : guest_id,
          taxes: taxes,
          orderType: orderType,
        });
        if (response?.data?.type === "new") {
          setOrderNumber(response?.data?.order?.orderNo);
          navigate("/order", { replace: true });
          localStorage.setItem("orderNumber", response?.data?.order?.orderNo);

          await sendNotification({
            userId: restaurantId,
            title: "Order Status",
            body: `Order is placed from ${
              userData?.userName ? userData?.userName : `a guest user`
            } orderNo: ${response?.data?.order?.orderNo}`,
          });
          // toast.showSuccessToast("Order created successfully");
          setOrderSucessfull(true);
        } else if (response?.data?.type === "update") {
          toast.showSuccessToast("Order updated successfully");
          handleOnClose();
        }
      } catch (error) {
        console.log(error);
        toast.showErrorToast("Error creating order", error.message);
      } finally {
        setClickOnConfirm(false);
      }
    } else {
      // navigate("/login", { state: { from: "/order" } });
      setLoginState({ from: "/order" });
      setShowLogin("login");
      setClickOnConfirm(false);
    }
  };
  useEffect(() => {
    if (itemsInCart?.length > 0) {
      setCartItems(itemsInCart);
    }
  }, [itemsInCart]);

  useEffect(() => {
    async function isLogged() {
      const response = await userIsLogged();
      if (response?.status == 201) {
        setUserLogged(true);
      } else {
        setUserLogged(false);
      }
    }
    isLogged();
  }, [showLogin]);

  useEffect(() => {
    const guest_id = localStorage.getItem("guestUserId");

    async function isLogged() {
      if (
        location.search &&
        location.search?.includes("login") &&
        (guest_id || userLogged)
      ) {
        handleConfirmOrder();
      }
    }
    isLogged();
  }, [location, cartitems, userLogged]);
  useEffect(() => {
    if (itemsInCart?.length === 0) {
      navigate("/home", { replace: true });
    }
  }, [itemsInCart]);
  const handleClearAll = () => {
    dispatch(clearCart());
  };

  const sty = { padding: "10px", width: "100px" };
  return (
    <>
      <div className="h-100" style={{ position: "relative" }}>
        <div className="d-flex py-1 mx-2 pt-4 justify-content-between bg-white w-100 z-2 position-sticky top-0">
          <div className="d-flex">
            <Link to={"/home"} replace={true}>
              <MdOutlineArrowBackIos size={25} color="black" />{" "}
            </Link>
            {/* "fw-bold mx-1" */}
            {/* <h5 className={styles.HeadingMyOrders}>
            {" "}
            {localStorage.getItem("restaurantName")}
          </h5> */}
          </div>
          <div className="mx-3">
            {/* <VscClearAll
            size={25}
            color="black"
            title="Delete All"
            onClick={handleShow}
          /> */}
            <p style={{ paddingRight: "8px" }} onClick={handleShow}>
              Clear Cart
            </p>
          </div>
        </div>

        <div className="overflow-y-auto overflow-x-hidden">
          {/* <OrderTypeModal
          orderType={orderType}
          setOrderType={setOrderType}
          type="new"
        /> */}
          {itemsInCart?.length > 0 && (
            <>
              <CartItems cartItemList={cartitems} />
              <BillDetail
                itemsInCart={cartitems}
                tableNumber={tableNumber}
                orderType={orderType}
              />
            </>
          )}

          <div className="text-center">
            <span onClick={handleConfirmOrder}>
              <Button
                showLoader={clickOnConfirm}
                value={"Confirm Order"}
                disabled={clickOnConfirm ? true : false}
                style={{
                  width: "88vw",
                  height: "40px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  marginBottom: "15px",
                }}
              />
            </span>
          </div>
        </div>
        {orderSucessfull && (
          <OrderSuccesfulCard
            isVisible={orderSucessfull}
            onClose={handleOnClose}
            orderId={orderNumber}
            tableNo={tableNumber}
          />
        )}

        {show && (
          <CommonModal
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            btn2Text={"Yes"}
            btn2OnClick={handleClearAll}
            contentStyle={{
              fontWeight: 600,
              padding: "20px",
              fontSize: "20px",
              textAlign: "center",
            }}
            btn1Style={sty}
            btn2Style={sty}
            btn2Class={styles.btn2Class}
            FooterStyle={{ justifyContent: "space-evenly" }}
            centered
          >
            Are you sure you want to delete all the Items?{" "}
          </CommonModal>
        )}
      </div>
      {showLogin === "login" ? (
        <LoginPage
          setShowLogin={setShowLogin}
          showLogin={showLogin}
          setLoginState={setLoginState}
          loginState={loginState}
        />
      ) : null}
      {showLogin === "otpverify" ? (
        <OtpVerify
          setShowLogin={setShowLogin}
          showLogin={showLogin}
          loginState={loginState}
        />
      ) : null}
    </>
  );
};
export default MyOrders;
