import React, { useEffect, useState, useRef } from "react";
import styles from "./CustomizeItemPage.module.css";
import { useDispatch } from "react-redux";
import {
  updateItem,
  updateInstruction,
  removeItem,
} from "../../../store/cartSlice";
import { getFoodTypeColor } from "../../../constants";
import { addItem } from "../../../store/cartSlice";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { RxCross2 } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import { motion } from "framer-motion";
import { getLabelByPrice } from "../../../constants";
import useToast from "../../../utils/toast/useToast";
const CustomizeItemPage = ({
  item,
  setShowCustomize,
  setShowCustomizePage,
  setShowConfirmOrder,
  showCustomizePage,
  itemsInCart,
  sameItem = false,
  newItem = false,
}) => {
  const dispatch = useDispatch();
  const [selectedQuantity, setselectedQuantity] = useState(
    getLabelByPrice(item.quantityAddOn, item.basePrice)
  );
  const toast = useToast();
  const [price, setPrice] = useState(item.basePrice);
  const [hideItemName, setHideItemName] = useState(false);
  const [basePrice, setBasePrice] = useState(item.basePrice);
  const [instructions, setInstructions] = useState(item.instructions || ""); // State for textarea value
  const instructionsRef = useRef(null);
  const [badges, setBadges] = useState(item.miscellaneous);
  const [selectedAddOns, setSelectedAddOns] = useState(item.addOns || []);
  const [selectedBadges, setSelectedBadges] = useState(
    item?.selectedBadges || []
  );
  const [hideItemDescription, setHideItemDescription] = useState(false);

  const handleOutsideClick = () => {
    setShowCustomizePage(false);
  };
  useEffect(() => {
    const handleBodyScroll = () => {
      if (showCustomizePage) {
        disableBodyScroll(document.body);
      } else {
        enableBodyScroll(document.body);
      }
    };
    handleBodyScroll(); // Call initially to handle initial state

    return () => {
      enableBodyScroll(document.body); // Ensure scroll is enabled on unmount
    };
  }, [showCustomizePage]);

  useEffect(() => {}, [item]);
  useEffect(() => {
    const addOnsPrice = selectedAddOns.reduce(
      (total, addOn) => total + addOn.price,
      0
    );
    setPrice(basePrice + addOnsPrice);
  }, [selectedAddOns, basePrice]);

  const handleUpdateCart = () => {
    dispatch(
      updateItem({
        ...item,
        total: price,
        basePrice: basePrice,
        quantityLevel: selectedQuantity,
        instructions: instructions,
        addOns: selectedAddOns,
        selectedBadges: selectedBadges,
      })
    );

    setShowCustomizePage(false);
  };
  const handleAddOnClick = (addOn, choice) => {
    const isSelected = selectedAddOns.some(
      (selected) => selected._id === choice._id
    );
    let updatedAddOns;

    if (isSelected) {
      updatedAddOns = selectedAddOns.filter(
        (selected) => selected._id !== choice._id
      );
    } else {
      const addOnIds = addOn.choices.map((a) => a._id);
      const selectedAddOnIds = selectedAddOns.map((a) => a._id);
      const commonIds = addOnIds.filter((id) => selectedAddOnIds.includes(id));

      // Check if maximum choices have been reached
      if (commonIds && commonIds.length >= addOn.maxChoices) {
        // Remove the last selected add-on and add the new one
        updatedAddOns = [
          ...selectedAddOns.filter(
            (_, index) => index !== selectedAddOns.length - 1
          ),
          choice,
        ];
      } else {
        // Otherwise, add the new add-on
        updatedAddOns = [...selectedAddOns, choice];
      }
    }

    setSelectedAddOns(updatedAddOns);
  };

  const handleInstructionChange = (e) => {
    // if (e.target.value === "") {
    //   setSelectedBadges([]);
    // }
    setInstructions(e.target.value.replace(/\s+/g, " "));
  };

  const handleUpdateInstructions = () => {
    dispatch(
      updateInstruction({
        Olditem: item,
        basePrice: basePrice,
        total: price,
        quantityLevel: selectedQuantity,
        instructions: instructions,
        addOns: selectedAddOns,
        selectedBadges: selectedBadges,
      })
    );
    setShowCustomizePage(false);
  };

  const handleAddToCart = () => {
    dispatch(
      addItem({
        ...item,
        quantity: 1,
        total: price,
        basePrice: basePrice,
        quantityLevel: selectedQuantity,
        instructions: instructions,
        addOns: selectedAddOns,
        selectedBadges: selectedBadges,
      })
    );
    setShowCustomize && setShowCustomize(true);
    setShowConfirmOrder && setShowConfirmOrder(true);
    setShowCustomizePage(false);
  };

  const handleQuantityChange = (value) => {
    setBasePrice(value.price);
    setselectedQuantity(getLabelByPrice(item.quantityAddOn, value.price));
    setPrice(value.price);
  };

  const handleBadgeClick = (badgeText) => {
    console.log({ selectedBadges });
    const available = selectedBadges.includes(badgeText);
    const availableInItem = item?.selectedBadge?.includes(badgeText);

    // if(availableInItem){

    // }
    if (available) {
      setSelectedBadges(
        selectedBadges.filter((badge) => badge.trim() !== badgeText)
      );
    } else {
      setSelectedBadges((prevBadges) => [...prevBadges, badgeText]);
    }
  };
  const handleCrossClick = () => {
    setInstructions("");
    // setSelectedBadges([]);
  };
  const handleRemoveItem = () => {
    dispatch(removeItem({ ...item, quantity: 1 }));
  };

  const handleAddUpdateClick = (e) => {
    if (sameItem) {
      handleUpdateInstructions();
    } else if (newItem) {
      handleAddToCart();
    } else {
      handleUpdateCart();
    }
  };

  return (
    <div
      className={`${styles.overlay} container-fluid`}
      onClick={handleOutsideClick}
    >
      <motion.div
        initial={{ translateY: "100%" }}
        animate={{ translateY: "0%" }}
        transition={{ duration: 0.4 }}
        className={`modal-dialog ${styles.modalDialog}`}
        style={{
          position: "absolute",
          width: "100%",
          height: "80%",
        }}
      >
        <div
          className="modal-content"
          style={{
            height: "100%",
            background: "#fff",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={"modal-header"}
            style={{
              alignItems: "flex-start",
              padding: "10px 10px 10px",
            }}
          >
            <div className="w-70">
              <div className="d-flex align-items-top">
                <div style={{ fontSize: "18px" }}>
                  <div
                    className={styles.foodTag1}
                    style={{
                      border: `1px solid ${getFoodTypeColor(item?.foodType)}`,
                    }}
                  >
                    <div
                      className={styles.disc}
                      style={{
                        backgroundColor: getFoodTypeColor(item?.foodType),
                      }}
                    ></div>
                  </div>
                </div>
                <h4
                  className={
                    item?.name.length > 3 && !hideItemName
                      ? `m-0 ${styles.foodItemName} ${styles.itemNameHidden}`
                      : `m-0 ${styles.foodItemName} pb-4`
                  }
                  onClick={() => setHideItemName(!hideItemName)}
                >
                  {item?.name}
                </h4>
              </div>
            </div>

            <RxCross2
              size={40}
              onClick={() => setShowCustomizePage(false)}
              className={styles.exit}
            />
          </div>
          {item.description && (
            <div
              className={`${styles.itemDescription} ${styles.foodItemDescription}`}
              style={{
                padding: "0 20px 20px",
                wordBreak: "break-word",
                margin: "0px",
              }}
            >
              {item?.description?.length > 180 && !hideItemDescription
                ? item?.description?.slice(0, 180) + "..."
                : item?.description}
              {item?.description?.length > 180 && !hideItemDescription && (
                <span
                  style={{ color: "rgb(28, 147, 206)", cursor: "pointer" }}
                  onClick={() => setHideItemDescription(!hideItemDescription)}
                >
                  {" "}
                  show more
                </span>
              )}
              {item?.description?.length > 180 && hideItemDescription && (
                <span
                  style={{ color: "rgb(28, 147, 206)", cursor: "pointer" }}
                  onClick={() => setHideItemDescription(!hideItemDescription)}
                >
                  {" "}
                  show less
                </span>
              )}
            </div>
          )}
          <div
            style={{
              // height: "52vh",
              overflowY: "auto",
              backgroundColor: "white",
              // padding: "2px 8px",
              scrollbarWidth: "thin",
              padding: "0px 10px 70px",
              borderTop: "0.1px solid #e5e5e5",
              maxHeight: "calc(80vh - 70px)",
            }}
            className="modal-body"
          >
            {item?.quantityAddOn.length > 0 && (
              <div className={`${styles.quantity} d-flex flex-column pt-2`}>
                <h5 className={`text-left ${styles.propertySpecify}`}>
                  QUANTITY
                </h5>
                <div className={styles.radioContainer}>
                  {item.quantityAddOn.map((quant) => (
                    <div
                      className={`${styles.halfPlate}`}
                      onClick={() => handleQuantityChange(quant)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        {" "}
                        <input
                          id={quant.label}
                          className={`${styles.inputBtn} mx-2`}
                          type="radio"
                          name="quantity"
                          checked={
                            price -
                              selectedAddOns?.reduce(
                                (total, addOn) => total + addOn.price,
                                0
                              ) ===
                            quant?.price
                          }
                          onChange={() => handleQuantityChange(quant)}
                        />
                        <label
                          htmlFor={quant.label}
                          className={styles.quantityLabel}
                        >
                          {quant.label}
                        </label>
                      </div>

                      <div className={`d-flex`}>
                        <span className={`${styles.addonPrice}`}>
                          ₹{quant?.price}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {item.foodItemsAddOn_id.length > 0 && (
              <div className={`${styles.quantity}`}>
                <h5 className={`text-left mb-10 ${styles.propertySpecify}`}>
                  ADD-ONS
                </h5>
                <div className={styles.radioContainer}>
                  {item.foodItemsAddOn_id.map((addOn) => (
                    <div key={addOn.name} className="my-2">
                      <h5 className={`${styles.quantityLabel} my-2 mx-2`}>
                        {addOn.name}{" "}
                        <span className="text-muted ">
                          ({addOn.description})
                        </span>
                      </h5>
                      <h6>Select upto {addOn.maxChoices}</h6>

                      <div className={`mx-2`}>
                        {addOn.choices.map((choice) => (
                          <div
                            className={`${styles.halfPlate}`}
                            key={choice.id + 1}
                            onClick={() => handleAddOnClick(addOn, choice)}
                          >
                            <div className="d-flex justify-content-evenly align-items-center">
                              <input
                                id={`addon-${choice._id}`}
                                className={styles.inputBtn}
                                type="checkbox"
                                checked={selectedAddOns.some(
                                  (selected) => selected._id == choice._id
                                )}
                                onChange={() => handleAddOnClick(addOn, choice)}
                              />
                              <div
                                className={styles.foodTag}
                                style={{
                                  border: `1px solid ${
                                    addOn.veg === "veg"
                                      ? "#15A73E"
                                      : addOn.veg === "non-veg"
                                      ? "#8F3417"
                                      : "#d19602"
                                  }`,
                                }}
                              >
                                <div
                                  className={styles.disc}
                                  style={{
                                    backgroundColor:
                                      addOn.veg === "veg"
                                        ? "#15A73E"
                                        : addOn.veg === "non-veg"
                                        ? "#8F3417"
                                        : "#d19602",
                                  }}
                                ></div>
                              </div>
                              <label className={styles.quantityLabel}>
                                {choice.name}
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className={styles.addonPrice}>
                                +₹{choice.price}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.quantity}>
              <div className="mb-3">
                <label htmlFor="instructions" className="">
                  <h5 className={`text-left ${styles.propertySpecify} `}>
                    {" "}
                    ADD INSTRUCTIONS
                  </h5>
                </label>
                <div className="cust-instruction-row mb-2 fs-5 p-0 text-start">
                  {badges.map((badgeText, index) => (
                    <span
                      className={`${styles.badge} ${
                        selectedBadges.includes(badgeText.label)
                          ? styles.selectedBadge
                          : ""
                      }`}
                      key={badgeText.label}
                      id={badgeText.label}
                      onClick={() => handleBadgeClick(badgeText.label)}
                    >
                      {badgeText.label}
                    </span>
                  ))}
                </div>

                <div className="position-relative">
                  <textarea
                    className="form-control"
                    name=""
                    id="instructions"
                    rows="3"
                    style={{ resize: "none" }}
                    ref={instructionsRef}
                    value={instructions}
                    onChange={(e) => handleInstructionChange(e)}
                    placeholder="Enter instructions here..."
                  ></textarea>
                  {instructions && (
                    <RxCross2
                      className="position-absolute top-0 end-0 me-2 mt-2"
                      onClick={() => handleCrossClick()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <p className={styles.price}>₹{price}</p>
            <button className={styles.button} onClick={handleAddUpdateClick}>
              {newItem ? "Add Item" : "Update Item"}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CustomizeItemPage;
