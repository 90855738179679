import { useNavigate, useParams } from "react-router-dom";
import styles from "../styles/PreviousOrderDetails.module.css";
import { MdArrowBackIos } from "react-icons/md";
import BillDetailReplacement from "../components/PrevOrderDetailsPage/BillDetailReplacement";
import { Link } from "react-router-dom";
import { userIsLogged } from "../services/user";
import useToast from "../utils/toast/useToast";
import { persistor } from "../store/store";
import { orderStatus, getStatusColor } from "../constants";
import { useContext, useEffect, useState } from "react";
import { getRestaurantById } from "../services/restaurant";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import Invoice from "../components/reports/Invoice";
import { motion } from "framer-motion";
import { fetchOrdersById } from "../services/ordersService";
import SocketContext from "../socket/socket";
import CommonLoader from "../components/elements/common/Loader/CommanLoader";

const OrderDetails = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [orderDetail, setOrderDetail] = useState({});
  const { id } = useParams();
  const userId =
    JSON.parse(localStorage.getItem("userDetails"))?._id ||
    localStorage.getItem("guestUserId");
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  useEffect(() => {
    const order = async () => {
      const orderById = await fetchOrdersById(id);
      setOrderDetail(orderById?.orders[0]);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    // Call the function initially
    order();
    setLoading(true);

    // Refetch when window is focused
    const handleFocus = () => {
      order();
    };

    window.addEventListener("focus", handleFocus);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [id]);

  const handleOrderStatusUpdate = (data) => {
    if (data.userId === userId) {
      const updatedOrder = data.updatedOrder;
      setOrderDetail(updatedOrder);
    }
  };
  const handleDeleteOrder = (data) => {
    setOrderDetail({});
    navigate(-1, { replace: true });
  };
  console.log({ invoiceData });

  const items = orderDetail?.items;
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const generatePDF = async () => {
      const doc = <Invoice invoice={invoiceData} />;
      const blob = await pdf(doc).toBlob();
      setPdfBlob(blob);
    };

    if (invoiceData) {
      generatePDF();
      setLoadingInvoice(false);
    }
  }, [invoiceData]);
  setTimeout(() => {
    setLoadingInvoice(false);
  }, 2000);
  useEffect(() => {
    const getInvoiceData = async () => {
      const { orderNo, restaurant_id } = orderDetail;
      const userName = orderDetail?.user_id?.userName;
      const userContact = orderDetail?.user_id?.phoneNumber;
      const restaurant_data = await getRestaurantById(restaurant_id);
      setLoadingInvoice(false);
      if (restaurant_data?.data) {
        setRestaurantData(restaurant_data.data);
        setInvoiceData({
          id: orderNo,
          invoice_no: orderNo,
          customer: userName,
          restaurant: restaurant_data.data?.restaurantName,
          res_email: "pizzahut@gmail.com",
          res_contact: `+91 ${restaurant_data.data.phoneNumber}`,
          res_address: `${restaurant_data.data.streetAddress}, ${restaurant_data.data.district}, ${restaurant_data.data.state}`,
          email: "gurjindersinghm2@gmail.com",
          phone: `${userContact ? `+91 ${userContact}` : ""}`,
          // address: `mohali`,
          items: items,
          tax: 4,
        });
        setLoadingInvoice(false);
      } else {
        setInvoiceData(null);
        setLoadingInvoice(false);
      }
    };

    getInvoiceData();
  }, [orderDetail, items]);

  useEffect(() => {
    userIsLogged(navigate, toast);
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    if (!localStorage.getItem("reload-detail")) {
      localStorage.setItem("reload-detail", true);
      window.location.href = window.location.href;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("deleteOrderUpdate", handleDeleteOrder);
      socket.on("orderUpdateEvent", handleOrderStatusUpdate);
    }

    // Cleanup function to remove the event listener on component unmount
    return () => {
      socket?.on("deleteOrderUpdate", handleDeleteOrder);
      socket?.off("orderUpdateEvent", handleOrderStatusUpdate);
    };
  }, [socket]);
  return (
    <div className={styles.main}>
      <div
        className="position-sticky  bg-white w-100 z-1"
        style={{ top: "0px", paddingTop: "20px" }}
      >
        <div
          className="d-flex align-items-center mb-2"
          style={{ height: "30px" }}
        >
          <Link to={"/welcome"} replace={true}>
            <MdArrowBackIos style={{ color: "black", fontSize: "26px" }} />
          </Link>
          <h1 className={`my-3 ${styles.h1}`}>
            <span>ORDER DETAILS</span>
          </h1>
        </div>
        {loading ? (
          <CommonLoader color={"black"} size={"40px"} />
        ) : (
          <div className={styles.banner}>
            <div className={` ${styles.ordNo}`}>{orderDetail.orderNo}</div>
            <h6
              className={styles.h6}
              style={{
                color: "white",
                backgroundColor: `${getStatusColor(
                  orderStatus[orderDetail.orderStatus]
                )}`,
              }}
            >
              {orderStatus[orderDetail.orderStatus] === "Received" ||
              orderStatus[orderDetail.orderStatus] === "received"
                ? "Pending"
                : orderStatus[orderDetail.orderStatus] === "InProcess"
                ? "Preparing"
                : orderStatus[orderDetail.orderStatus]}
            </h6>
          </div>
        )}
      </div>
      {!loading ? (
        <div className={`${styles.overflow} "`}>
          {/* {orderDetail?.orderType && (
            <OrderType orderTypeProcessed={orderDetail?.orderType} />
          )} */}
          <div className={styles.outer}>
            {items?.map((item) => (
              <div className={styles.itemcard} key={item.name}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {item.addOns && item.addOns.length ? " (" : ""}
                      {`${
                        item.addOns && item.addOns.length
                          ? item.addOns.reduce(
                              (result, value, index) =>
                                result + (index === 0 ? "" : ", ") + value.name,
                              ""
                            )
                          : ""
                      }`}
                      {item.addOns && item.addOns.length ? ")" : ""}
                    </span>
                  </h2>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "rgba(117, 114, 114, 1)",
                      fontWeight: "500",
                      textAlign: "right",
                    }}
                  >
                    Quantity {item?.quantity}
                  </span>
                </div>
                <div className={styles.flex}>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "rgba(117, 114, 114, 1)",
                      fontWeight: "500",
                    }}
                  >
                    {item?.quantityLevel}
                  </span>

                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "rgba(250, 74, 12, 1)",
                    }}
                  >
                    ₹{item && item?.total ? item?.total : item?.basePrice}
                  </span>
                </div>
                <div>{item?.instructions}</div>
              </div>
            ))}
          </div>
          <BillDetailReplacement
            list={orderDetail?.items}
            orderDetail={orderDetail}
            orderType={orderDetail?.orderType}
          />
          {orderDetail?.orderStatus === "delivered" &&
            orderDetail?.payment?.paid &&
            pdfBlob &&
            invoiceData &&
            restaurantData && (
              <div className="text-center">
                <motion.button
                  className={styles.button}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setLoadingInvoice(true)}
                >
                  <PDFDownloadLink
                    document={<Invoice invoice={invoiceData} />}
                    fileName="invoice.pdf"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {loadingInvoice
                      ? "Loading document..."
                      : "Download Invoice"}
                  </PDFDownloadLink>
                </motion.button>
              </div>
            )}
        </div>
      ) : null}
    </div>
  );
};

export default OrderDetails;
